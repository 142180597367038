import YouTube from 'react-youtube'
import styled from 'styled-components'

import { hideBlockAt, marginBreakpoints } from '../../../../utils/commonAttrs'
import { BaseBlockProps } from '../../../../types/BaseBlockProps'
import { PlayerStatus } from './types'

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
`

export const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const EmbedWrapper = styled.div<BaseBlockProps>`
  width: 100%;
  margin: auto;

  ${({ marginBottom }) => marginBreakpoints({ marginBottom })};
  ${({ hiddenAt }) => hideBlockAt({ hiddenAt })}
`

export const HeightPreserver = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  box-shadow: 0 5px 10px ${({ theme }) => theme.tokens.interface.shadow.regular};
  border-radius: 8px;
  overflow: hidden;
`

export const Video = styled(YouTube)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
`

export const Image = styled.img<{ status: PlayerStatus }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 5px 10px ${({ theme }) => theme.tokens.interface.shadow.regular};
  overflow: hidden;
  cursor: pointer;
  z-index: 15;
  opacity: ${props => (props.status === 'play' ? 0 : 1)};
  transition: all 0.5s;
`
