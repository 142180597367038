import React, { useState, useEffect, FC } from 'react'
import YouTube from 'react-youtube'

import {
  Container,
  EmbedWrapper,
  HeightPreserver,
  Image,
  PlayerWrapper,
  Video,
} from './YouTubeVideo.styles'
import { PlayerStatus } from './types'
import { BaseBlockProps } from '../../../../types/BaseBlockProps'

type Props = BaseBlockProps & {
  url: string
  overlay: string
  childBlock: boolean
}

export const YouTubeVideo: FC<Props> = ({
  url,
  overlay,
  marginBottom,
  childBlock,
  hiddenAt,
}) => {
  const [status, setStatus] = useState<PlayerStatus>('stop')
  const [player, setPlayer] = useState<
    ReturnType<YouTube['getInternalPlayer']>
  >(null)

  useEffect(() => {
    if (!player) return

    if (status === 'play') player.playVideo()
    if (status === 'stop') player.pauseVideo()
  }, [status, player])

  const match = /(.*?)(^|\/|v=)([a-z0-9_-]{11})(.*)?/gim.exec(url)
  const videoId = match![3]

  const VideoElement = (
    <Video
      containerClassName="youtube-embed-video"
      videoId={videoId}
      opts={{
        playerVars: {
          controls: 1,
        },
      }}
      onReady={({ target }) => setPlayer(target)}
      loading="lazy"
    />
  )

  const overlayObj = JSON.parse(overlay)

  const overlayImageURL = overlayObj
    ? (overlayObj.sizes.medium_large ?? overlayObj.sizes.full)?.url
    : null

  const OverlayImage =
    !!overlayObj && !!overlayImageURL ? (
      <Image
        status={status}
        src={overlayImageURL}
        alt="Video overlay"
        onClick={() => setStatus('play')}
        loading="lazy"
      />
    ) : null

  const Player = (
    <EmbedWrapper marginBottom={marginBottom} hiddenAt={hiddenAt}>
      <HeightPreserver>
        {status !== 'play' && OverlayImage}
        {VideoElement}
      </HeightPreserver>
    </EmbedWrapper>
  )

  if (childBlock) return Player

  return (
    <Container>
      <PlayerWrapper>{Player}</PlayerWrapper>
    </Container>
  )
}
